
// @ is an alias to /src
import CKEditor from '@/components/CKEditor.vue'
import { SET_CONTENT_REQUEST } from '@/store/actions/website_content.ts'

import { mapGetters, mapState } from 'vuex'

//import Banner from '@/components/Banner.vue'

import youSure from '@/utils/ConfirmationModal.ts'
import AlertModal from '@/components/AlertModal.vue'

export default {
    name: 'Excursies',
    data: function() {
        return {
            errors: [],
            success: null,
        }
    },
    components: {
        CKEditor,
        //Banner,
        AlertModal,
    },
    methods: {
        get_content: function(content_section) {
            let store = this.$store.state.website_content.content
            if (!(content_section in store)) return ''
            return store[content_section]
        },
        set_content: function(name, new_content) {
            this.$store.dispatch(SET_CONTENT_REQUEST, {name: name, content: new_content}).then()
        },
    },
    computed: {
        ...mapState({
            projects: (state: any) => state.project.projects,
        }),
        ...mapGetters(['isAdmin']),
    },
}
